<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <div class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>

          <h1 class="page_title">{{ $route.meta.title }}</h1>
        </div>
        <main class="space-y-10">
          <section class="bg-white rounded-2xl p-4 space-y-4">
            <div class="flex items-center justify-between">
              <h1 class="bigTitle">LINE 快速登入 (LINE Login)</h1>

              <vs-switch color="success" v-model="loginActive" :disabled="!(line_cid && line_csecret)">
                <template #off>
                  <span class="text-sm">已停用</span>
                </template>
                <template #on>
                  <span class="text-sm">啟用中</span>
                </template>
              </vs-switch>
            </div>

            <div class="flex w-full space-x-4">
              <div class="w-full">
                <h2 class="title">Channel ID</h2>
                <div class="relative">
                  <ValidationProvider name="Channel ID" :rules="`${loginActive ? 'required|max:40' : ''}`" v-slot="{ errors }">
                    <vs-input v-model="line_cid" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>

              <div class="w-full">
                <h2 class="title">Channel Secret</h2>
                <div class="relative">
                  <ValidationProvider name="Channel Secret" :rules="`${loginActive ? 'required|max:40' : ''}`" v-slot="{ errors }">
                    <vs-input v-model="line_csecret" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="flex items-center justify-between">
              <h1 class="bigTitle">LINE BOT (LINE Messaging)</h1>
            </div>

            <div class="flex w-full space-x-4">
              <div class="w-full">
                <h2 class="title">Bot Basic ID</h2>
                <div class="relative">
                  <ValidationProvider name="Bot Basic ID" :rules="`${loginActive ? 'required|max:50' : ''}`" v-slot="{ errors }">
                    <vs-input v-model="line_basicid" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="flex w-full space-x-4">
              <div class="w-full">
                <h2 class="title">Webhook URL
                  <button @click="copyToClipboard(`${ line_webhook_url }`)">
                    <svg-icon icon-class="copy" class="xs" style="fill: currentColor"></svg-icon>
                  </button>
                </h2>
                <div class="relative">
                  <ValidationProvider name="Webhook URL">
                    <vs-input v-model="line_webhook_url" disabled="disabled" />
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="flex w-full space-x-4">
              <div class="w-full">
                <h2 class="title">Channel Access Token</h2>
                <div class="relative">
                  <ValidationProvider name="Channel Access Token" :rules="`${loginActive ? 'required|max:300' : ''}`" v-slot="{ errors }">
                    <vs-input v-model="line_channel_access_token" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="flex items-center justify-between">
              <h1 class="bigTitle">LINE LIFF</h1>
            </div>

            <div class="flex w-full space-x-4">
              <div class="w-full">
                <h2 class="title">LIFF ID</h2>
                <div class="relative">
                  <ValidationProvider name="LIFF ID" :rules="`${loginActive ? 'required|max:50' : ''}`" v-slot="{ errors }">
                    <vs-input v-model="line_liffid" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="flex w-full space-x-4">
              <div class="w-full">
                <h2 class="title">LIFF 登入
                  <button @click="copyToClipboard(`${ line_login_callback }`)">
                    <svg-icon icon-class="copy" class="xs" style="fill: currentColor"></svg-icon>
                  </button>
                </h2>
                <div class="relative">
                  <ValidationProvider name="LIFF LOGIN CALLBACK">
                    <vs-input v-model="line_login_callback" disabled="disabled" />
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="flex w-full space-x-4">
              <div class="w-full">
                <h2 class="title">LIFF 現正熱賣
                  <button @click="copyToClipboard(`${ line_hotproduct }`)">
                    <svg-icon icon-class="copy" class="xs" style="fill: currentColor"></svg-icon>
                  </button>
                </h2>
                <div class="relative">
                  <ValidationProvider name="LIFF HOTPRODUCT">
                    <vs-input v-model="line_hotproduct" disabled="disabled" />
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="flex w-full space-x-4">
              <div class="w-full">
                <h2 class="title">LIFF 即將開團
                  <button @click="copyToClipboard(`${ line_willproduct }`)">
                    <svg-icon icon-class="copy" class="xs" style="fill: currentColor"></svg-icon>
                  </button>
                </h2>
                <div class="relative">
                  <ValidationProvider name="LIFF WILLPRODUCT">
                    <vs-input v-model="line_willproduct" disabled="disabled" />
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="flex w-full space-x-4">
              <div class="w-full">
                <h2 class="title">LIFF 會員中心
                  <button @click="copyToClipboard(`${ line_member }`)">
                    <svg-icon icon-class="copy" class="xs" style="fill: currentColor"></svg-icon>
                  </button>
                </h2>
                <div class="relative">
                  <ValidationProvider name="LIFF MEMBER">
                    <vs-input v-model="line_member" disabled="disabled" />
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="flex w-full space-x-4">
              <div class="w-full">
                <h2 class="title">LIFF 我的訂單
                  <button @click="copyToClipboard(`${ line_orders }`)">
                    <svg-icon icon-class="copy" class="xs" style="fill: currentColor"></svg-icon>
                  </button>
                </h2>
                <div class="relative">
                  <ValidationProvider name="LIFF ORDERS">
                    <vs-input v-model="line_orders" disabled="disabled" />
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="flex w-full space-x-4">
              <div class="w-full">
                <h2 class="title">揪朋友
                  <button @click="copyToClipboard(`${ line_share }`)">
                    <svg-icon icon-class="copy" class="xs" style="fill: currentColor"></svg-icon>
                  </button>
                </h2>
                <div class="relative">
                  <ValidationProvider name="LIFF SHARE">
                    <vs-input v-model="line_share" disabled="disabled" />
                  </ValidationProvider>
                </div>
              </div>
            </div>
 
          </section>
          <hr />
          <div class="flex justify-end">
            <vs-button size="large" ref="saveData" :disabled="invalid" @click="saveData(invalid)"
              ><span class="font-medium">儲存</span></vs-button
            >
          </div>
        </main>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginActive: false,
      line_cid: '',
      line_csecret: '',
      line_liffid: '',
      line_login_callback: '',
      line_hotproduct: '',
      line_willproduct: '',
      line_member: '',
      line_orders: '',
      line_basicid: '',
      line_webhook_url: '',
      line_channel_access_token: '',
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$axios({
        url: 'front/set/app/getLineData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          let data = res.data.Data

          this.line_login = data.line_login
          this.line_cid = data.line_cid
          this.line_csecret = data.line_csecret
          this.line_liffid = data.line_liffid
          this.line_login_callback = data.line_login_callback
          this.line_hotproduct = data.line_hotproduct
          this.line_willproduct = data.line_willproduct
          this.line_member = data.line_member
          this.line_orders = data.line_orders
          this.line_share = data.line_share
          this.line_basicid = data.line_basicid
          this.line_webhook_url = data.line_webhook_url
          this.line_channel_access_token = data.line_channel_access_token

          data.line_login === 1 ? (this.loginActive = true) : (this.loginActive = false)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    saveData(invalid) {
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.saveData,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let data = {
        lang: 0,
        line_login: this.line_login,
        line_cid: this.line_cid,
        line_csecret: this.line_csecret,
        line_liffid: this.line_liffid,
        line_basicid: this.line_basicid,
        line_channel_access_token: this.line_channel_access_token,
      }

      this.$axios({
        url: 'front/set/app/uLineData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadData()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 複製優惠碼到剪貼簿
    async copyToClipboard(link) {
      try {
        await navigator.clipboard.writeText(link)
        this.$myVS.openNoti('複製成功', '', undefined)
      } catch (err) {
        alert('您的瀏覽器版本不支援此種複製方式，請手動複製。', err)
      }
    },
  },
  computed: {
    line_login: {
      get() {
        return this.loginActive ? 1 : 0
      },
      //沒加set會報錯
      set() {},
    },
  },
  watch: {
    line_cid: function () {
      if (this.line_cid === '' && this.line_csecret === '') {
        this.loginActive = false
      }
    },
    line_csecret: function () {
      if (this.line_cid === '' && this.line_csecret === '') {
        this.loginActive = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
  line-height: 20px;
}
</style>
